import React, { Fragment, useState, useContext, useEffect } from "react";
import { TextsContext } from '../../../context/textsContext';
import { TitleH3Component } from "../../../components/TitleH3Component";
import style from '../../../scss/collapse.module.scss';
import styleForm from '../../../scss/form.module.scss';
import AccordionItemComponent from "./AccordionItemComponent";
import { FEMALE_BENEFITS, OUTPATION_BENEFITS, useCotizador } from "../../../context/apiContext";
import AccordionSelectComponent from "./AccordionSelectComponent";
import { arrayIncludesNumber, boolIsTrue, extractNumberFromString, filterByMaximun, valusIsTrue } from "../../../helpers/toolsHelper";
import { AccordionItemTravelComponent } from "./AccordionItemTravelComponent";
import Stylegeneral from "../../../scss/general.module.scss";
import { useBenefitComplement } from "hooks/cotizador/BenefitComplementHook";
import { OrganOptions } from "components/OrganOptions";
const initialState = {
    1: false,
    2: false,
    3: false
};
const AccordionComponent = () => {
    const { texts } = useContext(TextsContext);
    const [openItem, setOpenItem] = useState(initialState);
    const [isOpen, setIsOpen] = useState(false);
    const {
        contextState,
        handleOnChange,
        selectedBenefitsIds,
        benefits,
        isFemale,
        organRiderQuery,
        maximun,
        handleChecks, step13Confirmation
    } = useCotizador();
    const outpatientComplementHandler = useBenefitComplement(OUTPATION_BENEFITS);
    const femaleComplementHandler = useBenefitComplement(FEMALE_BENEFITS);

    const toggleOpenItem = (itemId) => {
        setOpenItem((prevState) => ({
            ...initialState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleBoolSelect = (e) => {
        handleOnChange({ target: { name: e.target.name, value: e.target.value === 'true' ? true : false } });
    }

    const handleBoolSelectAmbulance = (e) => {
        handleOnChange({ target: { name: "nonAmbulance", value: true } });
        handleOnChange({ target: { name: e.target.name, value: e.target.value === 'true' ? true : false } });
        outpatientComplementHandler.updateComplements()
    }

    const handleBoolSelectFemale = (e) => {
        handleOnChange({ target: { name: "nonMaternidad", value: true } });
        handleOnChange({ target: { name: e.target.name, value: e.target.value === 'true' ? true : false } });
        femaleComplementHandler.updateComplements()
    }

    useEffect(() => {
        if (contextState.organ) {
            if (organRiderQuery?.data?.data?.length && !contextState.organRider) {
                try {
                    handleOnChange({
                        target: {
                            name: "organRider", value: organRiderQuery?.data?.data
                                ?.filter(filterByMaximun(maximun))
                                ?.sort((a, b) => extractNumberFromString(b?.description) - extractNumberFromString(a?.description))[0].factor
                        }
                    });
                    handleOnChange({
                        target: {
                            name: "organRiderName", value: organRiderQuery?.data?.data
                                ?.filter(filterByMaximun(maximun))
                                ?.sort((a, b) => extractNumberFromString(b?.description) - extractNumberFromString(a?.description))[0].description
                        }
                    });
                    handleOnChange({
                        target: {
                            name: "organId", value: organRiderQuery?.data?.data
                                ?.filter(filterByMaximun(maximun))
                                ?.sort((a, b) => extractNumberFromString(b?.description) - extractNumberFromString(a?.description))[0].id
                        }
                    });

                } catch (e) {

                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextState?.organ, contextState.organRider]);



    const paragraph_services_value_one = texts?.quote_pdf_patient_services_value_one;
    const services_value_one_title_marca = paragraph_services_value_one?.replace?.("®", `<span>&reg;</span>`) ?? '';

    return (
        <Fragment>
            <AccordionItemComponent openItem={openItem} itemNumber={1} onClick={() => {
                toggleOpenItem(1)
            }} title={texts?.menu_options_title_coverage ?? 'Coverage Options'}>
                <div id="example-collapse-text" className={`${style.Container_scroll}`}>
                    <div className={`${style.scroll} pe-4`}>
                        <AccordionSelectComponent title={texts?.step11_form_field_maximum ?? "Maximum Coverage Amount"}
                            benefit={"Maximum coverage per insured, per policy year"}
                            benefits={benefits.structuralData} />
                        <AccordionSelectComponent complements={["Providers Network", "Deductible Inside U.S."]} title={texts?.step11_form_field_geo ?? "Geographical coverage"}
                            benefit={"Geographical coverage"} benefits={benefits.structuralData} boolComplements="limitedCover" />
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_age_limit_title ?? "Age limit to apply"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_age_limit_value ?? "75"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_waiting_title ?? "Waiting period"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_waiting_value ?? "30 days"}
                                </TitleH3Component>
                            </div>
                        </div>


                        <AccordionSelectComponent
                            providersSignificant={true}
                            boolComplements="limitedCover"
                            title={texts?.step11_form_field_providers_network ?? 'Providers Network'}
                            benefit={'Providers Network'} benefits={benefits.structuralData} />

                        <AccordionSelectComponent
                            title={Object.values(selectedBenefitsIds).includes("-12") ? (texts?.step11_form_field_deductible ?? 'Deductible') : (texts?.step11_form_field_outside ?? 'Deductible outside U.S.')}
                            benefit={"Deductible Outside U.S."} benefits={benefits.structuralData} />
                        <AccordionSelectComponent title={texts?.step11_form_field_inside ?? "Deductible Inside U.S."}
                            benefit={"Deductible Inside U.S."}
                            benefits={benefits.structuralData} />
                        <AccordionSelectComponent title={texts?.step11_form_field_coinsurance ?? "Coinsurance"}
                            benefit={"Coinsurance"} benefits={benefits.structuralData} complements={"Maximum out of pocket"} />
                        <AccordionSelectComponent title={texts?.step11_form_field_pocket ?? "Maximum Out of Pocket"}
                            benefit={"Maximum out of pocket"} benefits={benefits.structuralData} />



                    </div>
                </div>
            </AccordionItemComponent>
            <AccordionItemComponent openItem={openItem} itemNumber={2} onClick={() => {
                toggleOpenItem(2)
            }} title={texts?.menu_options_title_benefits ?? 'Plan Benefits'}>
                <div id="example-collapse-text" className={`${style.Container_scroll}`}>
                    <div className={`${style.scroll} pe-4`}>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_standard_hospital_title ?? "Standard hospital room and board"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo}`}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_intensive_care_title ?? "Intensive care unit"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_surgeon_title ?? "Surgeon and anesthesiologist fees"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_adult_companion_title_under ?? `Adult companion accommodation expenses during a
                                hospitalization related to a hospitalized insured under
                                18 years old`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_adult_companion_value_under ?? "US$175 per night, max. of 30 nights"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_adult_companion_title_over ?? `Adult companion accommodation expenses during a
                                hospitalization related to a hospitalized insured over
                                18 years old`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_adult_companion_value_over ?? "100% UCR max. of 21 nights"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_diagnostic_title ?? "Diagnostic study services"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_prescribed_medications_title ?? "Prescribed medications during a hospitalization"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_prostheses_title ?? "Prostheses and medical appliances implanted during surgery"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <AccordionSelectComponent title={texts?.step4_form_field_congenital ?? "Congenital conditions"}
                            benefit={"Congenital conditions"}
                            benefits={benefits.regularData} />
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_physical_therapy_title ?? "Physical therapy and rehabilitation"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_durable_title ?? "Durable medical equipment"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_treatments_title ?? `Specialized treatments: occupational therapy, autism, sleep
                                apnea and other sleep disorders`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_treatments_value ?? "US$3,000"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_hiv_title ?? "HIV-AIDS (inpatient and outpatient)"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_hiv_value ?? "US$50,000 per lifetime (after a 24-month waiting period)"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_oncology_title ?? `Oncology: tests, treatment (chemotherapy and/or
                                radiotherapy) and medication (inpatient and outpatient)`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_dialysis_title ?? "Dialysis (inpatient and outpatient)"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_surgery_title ?? `Surgery to reduce the risk of cancer or prophylactic surgery
                                (after a 12-month waiting period)`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_surgery_value ?? "US$30,000"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_bariatric_title ?? "Bariatric surgery"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_bariatric_value ?? "US$10,000 per lifetime (after a 24-month waiting period)"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <AccordionSelectComponent title={texts?.step4_form_field_dental ?? "Dental treatment"}
                            benefit={"Dental treatment"} benefits={benefits.regularData} />

                        <AccordionSelectComponent
                            title={texts?.step4_form_field_hazardous_hobbies ?? "Injuries during the training or practice of hazardous hobbies and non-professional sports"}
                            benefit={"Injuries during the training or practice of non-professional hazardous hobbies and sports"}
                            benefits={benefits.regularData} />
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_palliative_title ?? "Palliative care for terminal cases"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_injuries_title_one ?? `Injuries during the training or practice of non-hazardous
                                hobbies and sports (professional or non-professional)`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ucr ?? "100% UCR"}
                                </TitleH3Component>
                            </div>

                        </div>

                        <AccordionSelectComponent
                            title={texts?.step5_form_field_extended_coverage ?? "Free extended coverage for eligible dependents after policyholder’s death"}
                            benefit={"Free extended coverage for eligible dependents after policyholder’s death"}
                            benefits={benefits.regularData} />




                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_reconstructive_title ?? "Reconstructive surgery after an accident or Illness"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_reconstructive_value ?? `100% UCR if medically necessary and as a result of a medical
                                    condition covered by the policy`}
                                </TitleH3Component>
                            </div>
                        </div>



                        <div
                            className={`d-flex justify-content-between align-items-center  pb-2 ${(valusIsTrue(contextState.organ)) ? "" : "d-none"}`}>
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.step7_form_field_organ_coverage ?? 'Organ Transplant Coverage'}
                            </TitleH3Component>

                            <div
                                className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <select
                                    name={"organRider"}
                                    className={` ${styleForm.selecfondo} raleway `}
                                    onChange={handleOnChange}
                                    value={contextState.organRider}
                                    onClick={() => setIsOpen(!isOpen)}
                                    onBlur={() => setIsOpen(false)}
                                    onFocus={() => setIsOpen(false)}
                                >

                                    <OrganOptions isOpen={isOpen} />


                                </select>
                            </div>
                        </div>

                        {
                            (boolIsTrue(contextState.organ)) && (
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-1">
                                        <TitleH3Component
                                            styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                            {texts?.quote_pdf_benefits_title ?? 'Benefits for live donors'}
                                        </TitleH3Component>
                                        <div
                                            className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                            <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                                {texts?.quote_pdf_benefits_value ?? `US$60,000 per lifetime, included in the organ transplant
                                                benefit`}

                                            </TitleH3Component>
                                        </div>
                                    </div>
                                </>
                            )
                        }


                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_air_ambulance_title ?? "Emergency transportation by air ambulance"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_air_ambulance_value ?? "100% UCR, no deductible applies"}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_ground_ambulance_title ?? "Emergency transportation by ground ambulance"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_ground_ambulance_value ?? "100% UCR, no deductible applies"}
                                </TitleH3Component>
                            </div>
                        </div>



                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_return_ticket_title ?? `Insured’s and companion’s return ticket after an evacuation
                                by air ambulance`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_return_ticket_value ?? "US$1,000"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_repatriation_title ?? "Repatriation of mortal remains"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_repatriation_value ?? "US$25,000"}
                                </TitleH3Component>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_temporary_coverage_title ?? `Temporary coverage for accidents while the application is
                                being underwritten`}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_temporary_coverage_value ?? "US$30,000"}
                                </TitleH3Component>
                            </div>
                        </div>


                        <AccordionSelectComponent title={texts?.step5_form_field_deductible ?? "Deductible elimination"}
                            benefit={"Deductible elimination"} benefits={benefits.regularData} />
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_deductible_reduction_title ?? "Deductible reduction for no claims made"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_deductible_reduction_value ?? `Reduction of 50% of the deductible for 1 policy year after the
                                    3rd policy year`}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_pre_conditions_title ?? "Pre-existing conditions"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_pre_conditions_value ?? `Covered when disclosed in the application and with previous
                                    evaluation`}
                                </TitleH3Component>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <TitleH3Component
                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.quote_pdf_patient_services_title ?? "Patient services"}
                            </TitleH3Component>
                            <div className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    <p dangerouslySetInnerHTML={{ __html: services_value_one_title_marca }} className={`${Stylegeneral.marca_registradaContainer} ${Stylegeneral.texto_conmarca}`}></p>

                                </TitleH3Component>
                                <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                    {texts?.quote_pdf_patient_services_value_two ?? "• VIP Patient Concierge Services"}
                                </TitleH3Component>


                            </div>
                        </div>

                        <AccordionSelectComponent
                            title={texts?.step4_form_field_tratamiento_mental ?? "Maternity and newborn complications"}
                            benefit={"Inpatient mental health treatment"} benefits={benefits.debug} boolComplements="nonMaternidad" />

                    </div>
                </div>
            </AccordionItemComponent>
            <AccordionItemComponent openItem={openItem} itemNumber={3} onClick={() => {
                toggleOpenItem(3)
            }} title={texts?.menu_options_title_OutpatientBenefits ?? 'Beneficios Ambulatorios'} className={`${style.pie}`}>
                <div id="example-collapse-text" className={`${style.Container_scroll}`}>
                    <div className={`${style.scroll} pe-4`}>
                        <div className="d-flex justify-content-between align-items-center  pb-2">
                            <TitleH3Component
                                styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.step8_want_outpatient ?? 'Do you want to add outpatient benefits?'}
                            </TitleH3Component>

                            <div
                                className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <select value={contextState.outpationBenefitsEnabled} onChange={handleBoolSelectAmbulance}
                                    name={"outpationBenefitsEnabled"}
                                    className={` ${styleForm.selecfondo} raleway `}>
                                    <option value={false}>{texts?.general_form_option_no ?? 'No'}</option>
                                    <option value={true}>{texts?.general_form_option_yes ?? 'Yes'}</option>
                                </select>
                            </div>
                        </div>
                        <AccordionSelectComponent
                            title={texts?.step11_form_field_outpatient ?? "Maximum outpatient coverage per insured, per policy year"}
                            benefit={"Maximum outpatient coverage per insured, per policy year"}
                            benefits={benefits.debug} />

                        {contextState.outpationBenefitsEnabled && (
                            <>
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_emergency_title ?? "Emergency room"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_visits_title ?? "Physician and specialist visits"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_home_visits_title ?? "Physician and specialist home visits"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_diagnostic_title ?? "Diagnostic study services"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_outpatient_surgery_title ?? "Outpatient surgery"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_fees_title ?? "Surgeon and anesthesiologist fees"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_prostheses_title ?? "Prostheses and medical appliances implanted during surgery"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>


                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_durable_title ?? "Durable medical equipment"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>


                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_physical_therapy_title ?? "Physical therapy and rehabilitation"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_nurse_title ?? "Nurse care at home"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_nurse_value ?? "100% UCR following a covered hospitalization"}
                                        </TitleH3Component>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_alzheimer_title ?? "Alzheimer’s disease"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_hearing_aids_title ?? "Hearing aids"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_hearing_aids_value ?? " US$1,000 per lifetime"}
                                        </TitleH3Component>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_allergy_title ?? "Allergy treatments"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_ucr ?? "100% UCR"}
                                        </TitleH3Component>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <TitleH3Component
                                        styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                        {texts?.quote_pdf_foot_disorders_title ?? "Surgical treatment of symptomatic foot disorders"}
                                    </TitleH3Component>
                                    <div
                                        className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                        <TitleH3Component styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                            {texts?.quote_pdf_foot_disorders_value ?? "US$10,000 (after a 24-month waiting period)"}
                                        </TitleH3Component>
                                    </div>
                                </div>
                                <TitleH3Component
                                    styleGroup={`raleway  fw_700  color_primary_2 ${style.textMayuscula} ${style.titulo} ${style.titulocollasep}`}>
                                    {texts?.step5_Text_subtitulo_divisor ?? "Coberturas adicionales ambulatorias"}
                                </TitleH3Component>
                            </>
                        )


                        }



                        <AccordionSelectComponent
                            title={texts?.step5_form_field_therapy ?? 'Complementary therapy: chiropractor, psychologist, psychiatrist, osteopathy and/or acupuncture'}
                            benefit={"Complementary therapy: chiropractor, psychologist, psychiatrist, osteopathy and/or acupuncture"}
                            benefits={benefits.regularData} />
                        <AccordionSelectComponent
                            title={texts?.step5_form_field_medications ?? 'Prescribed medications'}
                            benefit={"Prescribed medications"} benefits={benefits.regularData} />

                        <AccordionSelectComponent
                            title={texts?.step5_form_field_mental_health ?? 'Mental health prescription medication'}
                            benefit={"Mental health prescription medication"} benefits={benefits.regularData} />


                    </div>
                </div>

            </AccordionItemComponent>
            <AccordionItemComponent openItem={openItem} itemNumber={4} onClick={() => {
                toggleOpenItem(4)
            }} title={texts?.menu_options_title_deducible ?? 'Reducción de deducible'} className={`${style.pie}`}>
                <div id="example-collapse-text" className={`${style.Container_scroll}`}>
                    <div className={`${style.scroll} pe-4`}>

                        <div className="d-flex justify-content-between align-items-center  pb-2">
                            <TitleH3Component
                                styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.step13_checkbox_phrase ?? 'Do you want to add outpatient benefits?'}
                            </TitleH3Component>

                            <div
                                className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <select value={contextState.step13Confirmation} onChange={handleBoolSelect}
                                    name={"step13Confirmation"}
                                    className={` ${styleForm.selecfondo} raleway `}>
                                    <option value={false}>{texts?.general_form_option_no ?? 'No'}</option>
                                    <option value={true}>{texts?.general_form_option_yes ?? 'Yes'}</option>
                                </select>
                            </div>
                        </div>


                    </div>
                </div>

            </AccordionItemComponent>
            <AccordionItemComponent openItem={openItem} itemNumber={5} onClick={() => {
                toggleOpenItem(5)
            }} title={texts?.menu_options_title_riders ?? 'Riders'} className={`${style.pie}`}>
                <div id="example-collapse-text">
                    <div className={`${style.scroll} pe-4`}>
                        <div className="d-flex justify-content-between align-items-center  pb-2">
                            <TitleH3Component
                                styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.step6_Text_Benefitsmaternidad ?? 'Do you want to add outpatient benefits?'}
                            </TitleH3Component>

                            <div
                                className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <select value={contextState.outpationBenefitsmaternidad} onChange={handleBoolSelectFemale}
                                    name={"outpationBenefitsmaternidad"}
                                    className={` ${styleForm.selecfondo} raleway `}>
                                    <option value={false}>{texts?.general_form_option_no ?? 'No'}</option>
                                    <option value={true}>{texts?.general_form_option_yes ?? 'Yes'}</option>
                                </select>
                            </div>
                        </div>
                        {(isFemale()) && (
                            <>
                                <AccordionSelectComponent
                                    title={texts?.step6_form_field_cesarean ?? "Maternity and elective cesarean"}
                                    benefit={"Maternity and cesarean"} benefits={benefits.regularData} boolComplements="nonMaternidad" />

                                {
                                    (!arrayIncludesNumber(Object.values(selectedBenefitsIds), 182) || !arrayIncludesNumber(Object.values(selectedBenefitsIds), 202)) && (
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <TitleH3Component
                                                styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                                {texts?.quote_pdf_pre_natal_title ?? "Pre-natal and post-natal care"}
                                            </TitleH3Component>
                                            <div
                                                className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                                <TitleH3Component
                                                    styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                                    {texts?.quote_pdf_pre_natal_value ?? "100% UCR under the maternity benefit limit"}
                                                </TitleH3Component>
                                            </div>
                                        </div>
                                    )
                                }

                                <AccordionSelectComponent
                                    title={texts?.step6_form_field_newborn ?? "Maternity and newborn complications"}
                                    benefit={"Maternity and newborn complications"} benefits={benefits.regularData} boolComplements="nonMaternidad" />

                                {
                                    (!arrayIncludesNumber(Object.values(selectedBenefitsIds), 182) || !arrayIncludesNumber(Object.values(selectedBenefitsIds), 202)) && (
                                        <>
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <TitleH3Component
                                                    styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                                    {texts?.quote_pdf_inclusion_title ?? "Inclusion of the newborn"}
                                                </TitleH3Component>
                                                <div
                                                    className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                                    <TitleH3Component
                                                        styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                                        {texts?.quote_pdf_inclusion_value ?? "Without underwriting if born from a covered maternity"}
                                                    </TitleH3Component>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <TitleH3Component
                                                    styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}>
                                                    {texts?.quote_pdf_stem_cells_title ?? "Extraction and storage of umbilical cord blood stem cells"}
                                                </TitleH3Component>
                                                <div
                                                    className={` py-1 px-3   ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                                    <TitleH3Component
                                                        styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}>
                                                        {texts?.quote_pdf_stem_cells_value ?? "US$1,000 per covered pregnancy"}
                                                    </TitleH3Component>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            </>
                        )}

                        <div className="d-flex justify-content-between align-items-center  pb-2">
                            <TitleH3Component
                                styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}>
                                {texts?.step14_Text_Agg_revisiones_salud ?? 'Do you want to add outpatient benefits?'}
                            </TitleH3Component>

                            <div
                                className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}>
                                <select value={contextState.step13Confirmation} onChange={step13Confirmation}
                                    name={"step13Confirmation"}
                                    className={` ${styleForm.selecfondo} raleway `}>
                                    <option value={false}>{texts?.general_form_option_no ?? 'No'}</option>
                                    <option value={true}>{texts?.general_form_option_yes ?? 'Yes'}</option>
                                </select>
                            </div>
                        </div>
                        <AccordionSelectComponent title={texts?.step4_form_field_checkup ?? "Preventive health checkup"}
                            benefit={"Preventive health checkup"}
                            benefits={benefits.regularData} />

                        <AccordionSelectComponent title={texts?.step5_form_field_surgery ?? 'Refractive surgery'}
                            benefit={"Refractive eye surgery"}  benefits={benefits.debug}  />
                        <AccordionSelectComponent title={texts?.step15_form_cirugia_bariatrica ?? 'Refractive surgery'}
                            benefit={"Bariatric surgery and/or weight loss medication"} benefits={benefits.debug} />
                    </div>
                </div>

            </AccordionItemComponent>
        </Fragment>
    )
}
export default AccordionComponent;
